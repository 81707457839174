import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: [Current Date]
        </Typography>
        <Typography variant="body1" paragraph>
          This Privacy Policy describes how [Your Website Name] ("we", "us", or "our") collects, uses, and shares personal information when you use our website [Your Website URL] (the "Service").
        </Typography>
        <Typography variant="h5" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that you provide directly to us, such as when you use our PNG to JPG converter tool. This may include the images you upload for conversion.
        </Typography>
        <Typography variant="h5" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to provide and improve our Service, including to process your image conversions.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Cookies and Similar Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar tracking technologies to track activity on our Service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Advertising
        </Typography>
        <Typography variant="body1" paragraph>
          We use Google AdSense to serve ads on our website. Google's use of advertising cookies enables it and its partners to serve ads based on your visit to our site and/or other sites on the Internet.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at [Your Contact Email].
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;