import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Button, Typography, Box, Container, Paper } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Helmet } from 'react-helmet';
import PrivacyPolicy from './components/PrivacyPolicy';

const Home = ({ convertedImage, onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/png': ['.png'] },
    multiple: false,
  });

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          PNG to JPG Converter
        </Typography>
        <Paper
          {...getRootProps()}
          sx={{
            p: 3,
            textAlign: 'center',
            cursor: 'pointer',
            bgcolor: isDragActive ? 'action.hover' : 'background.paper',
          }}
        >
          <input {...getInputProps()} />
          <CloudUploadIcon sx={{ fontSize: 48, mb: 2 }} />
          <Typography variant="h6">
            {isDragActive ? 'Drop the PNG file here' : 'Drag & drop a PNG file here, or click to select'}
          </Typography>
        </Paper>
        {convertedImage && (
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Converted Image:
            </Typography>
            <img src={convertedImage} alt="Converted JPG" style={{ maxWidth: '100%' }} />
            <Button
              variant="contained"
              color="primary"
              href={convertedImage}
              download="converted_image.jpg"
              sx={{ mt: 2 }}
            >
              Download JPG
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

function App() {
  const [convertedImage, setConvertedImage] = useState(null);

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const jpgDataUrl = canvas.toDataURL('image/jpeg');
        setConvertedImage(jpgDataUrl);
      };
      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  };

  return (
    <Router>
      <div>
        <Helmet>
          <title>PNG to JPG Converter - Free Online Tool</title>
          <meta name="description" content="Convert PNG images to JPG format easily with our free online tool. Fast, secure, and no registration required." />
          <meta name="keywords" content="PNG to JPG, image converter, online tool, free converter" />
        </Helmet>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home convertedImage={convertedImage} onDrop={onDrop} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;